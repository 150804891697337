// Packages
import React from 'react'
import { Result } from 'antd'

// Components
import { StyledButtonPrimary } from '../../components/Shared/Button/styledComponent'
import { navigate } from 'gatsby'
import LayoutMain from '../../layout'

const NotFoundPage: React.FC = () => {
  return (
    <LayoutMain>
      <Result
        status="404"
        title="404"
        subTitle="Pagina no encontrada"
        extra={
          <StyledButtonPrimary
            data-testid="button-go-home"
            type="submit"
            onClick={() => navigate('/')}
          >
            Regresar al Inicio
          </StyledButtonPrimary>
        }
      />
      ,
    </LayoutMain>
  )
}

export default NotFoundPage
